<template>
  <div>
    <CCard>
      <CCardBody>
        <CRow>
          <CCol sm="3">
						<CInput
							type="date"
							label="Date From"
							placeholder=""
							description="Masukkan tanggal awal pencarian."
							:value.sync="start_date"
							required
						/>
					</CCol>
          <CCol sm="3">
						<CInput
							type="date"
							label="Date To"
							placeholder=""
							description="Masukkan tanggal akhir pencarian."
							:value.sync="end_date"
							required
						/>
					</CCol>
          <CCol sm="3">
            <CButton
              style="margin-top: 30px;"
              color="primary"
              variant="outline"
              square
              size="sm"
              @click="getData(selected)"
            >
              Apply
            </CButton> &nbsp;
            <CButton
              style="margin-top: 30px;"
              color="success"
              variant="outline"
              square
              size="sm"
              @click="filterRefresh"
            >
              Refresh Chart
            </CButton>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>

    <CCard>
      <CCardBody>
        <CRow>
          <CCol sm="5">
            <h4 id="traffic" class="card-title mb-0">Total Visitor All Data Center</h4>
            <div class="small text-muted">{{date(new Date(), 'MMMM YYYY')}}</div>
          </CCol>
          <CCol sm="7" class="d-none d-md-block">
            <CButtonGroup class="float-right mr-3">
              <CButton
                color="outline-primary"
                v-for="(value, key) in ['day', 'month', 'year']"
                :key="key"
                class="mx-0"
                :pressed="value === selected ? true : false"
                @click="getData(selected = value)"
              >
                {{value}}
              </CButton>
            </CButtonGroup>
          </CCol>
        </CRow>
        <CChartLineExample style="height:450px; margin-top:40px;" :sites="sites" :data="data" :summary="selected" />
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import moment from 'moment';
import http from '@/utils/http-common';

import CChartLineExample from '@/views/charts/CChartLineExample'

export default {
  name: 'Dashboard',
  components: {
    CChartLineExample
  },
  data () {
    return {
      selected: 'month',
      site_id: 0,
      site_name: '',
      sites: [],
      data: [],
      start_date: '',
      end_date: this.date2Str(new Date(), 'yyyy-MM-dd')
    }
  },
  methods: {
    date(value, format) {
      return moment(String(value)).locale('Id').format(format);
    },
    date2Str(x, y) {
    	const z = {
        M: x.getMonth() + 1,
        d: x.getDate(),
        h: x.getHours(),
        m: x.getMinutes(),
        s: x.getSeconds()
    	};
    	y = y.replace(/(M+|d+|h+|m+|s+)/g, function(v) {
        return ((v.length > 1 ? "0" : "") + z[v.slice(-1)]).slice(-2)
    	});

    	return y.replace(/(y+)/g, function(v) {
        return x.getFullYear().toString().slice(-v.length)
    	});
		},
    lastDays() {
      const dt = new Date();
      dt.setDate(dt.getDate() - 30);
      this.start_date = this.date2Str(dt, 'yyyy-MM-dd');
    },
    filterRefresh() {
      this.lastDays();
      this.end_date = this.date2Str(new Date(), 'yyyy-MM-dd');
      this.getData(this.selected);
    },
    getData(summary) {
      let self = this;
      let start;
      let end;
      
			return http.get('/report/trend-visitors?site_id=0&summary=' + summary)
			.then(function (response) {
        let data = response.data.data;

        switch(summary) {
          case 'day':
            // code block
            start = new Date(self.start_date).getTime();
            end = new Date(self.end_date).getTime();

            data = data.filter(item => {
              let date = new Date(item.date).getTime();
              return date >= start && date <= end;
            })
            break;
          case 'month':
            // code block
            start = new Date(self.start_date).getMonth()+1;
            end = new Date(self.end_date).getMonth()+1;

            data = data.filter(item => {
              if (start > end) {
                return item.month <= start && item.month >= end;
              } else {
                return item.month >= start && item.month <= end;
              }
            })
            break;
          default:
            // code block
            start = new Date(self.start_date).getFullYear();
            end = new Date(self.end_date).getFullYear();

            data = data.filter(item => {
              return item.year >= start && item.year <= end;
            })
        }

        switch(summary.toLowerCase()) {
          case 'day':
            // code block
            data = data.sort((a,b) => (a.date > b.date) ? 1 : ((b.date > a.date) ? -1 : 0));
            break;
          case 'month':
            // code block
            if (start > end) {
              data = data.sort((a,b) => (b.month > a.month) ? 1 : ((a.month > b.month) ? -1 : 0));
            } else {
              data = data.sort((a,b) => (a.month > b.month) ? 1 : ((b.month > a.month) ? -1 : 0));
            }
            break;
          default:
            // code block
            data = data.sort((a,b) => (a.year > b.year) ? 1 : ((b.year > a.year) ? -1 : 0));
        }
				
        self.data = data;
			}).catch(function (error) {
				console.log(error);
			});
    },
    getSites() {
			const self = this;
			return http.get('/sites')
			.then(function (response) {
				const data = response.data.data;

        // self.sites.push({label: 'Semua Data Center', value: 0});

				for (let i = 0; i < data.length; i++) {
					self.sites.push(data[i]['site_name'])
				}
			}).catch(function (error) {
				console.log(error);
			});
		},
    siteChange(event) {
      this.site_id = event.target.value;
      this.siteDetail(event.target.value);
    },
    siteDetail(id) {
      const self = this;
			return http.get('/sites/' + id)
			.then(function (response) {
				self.site_name = response.data.data['site_name'];
			}).catch(function (error) {
				console.log(error);
			});
    }
  },
  mounted: function() {
    this.lastDays();
		this.getData('month');
    this.getSites();
	}
}
</script>

<style>

</style>
